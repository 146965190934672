.cameraDisplay {
  width: 100%;
  display: flex;
  margin: 0 auto;
  transform: rotateY(180deg);
}
.cameraNoDisplay {
  width: 100%;
  display: none;
  margin: 0 auto;
}

.deleteAccountContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switchCamera {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
}

.switchCamera .MuiInputLabel-root {
  margin-right: 10px;
  font-weight: bold;
}

.addOrgOption {
  display: flex;
  margin-top: 16px;
  align-items: center;
  justify-content: center;
}

.profileSettings {
  width: 50%;
  margin: 0 auto !important;
}

/* .rst__tree{
  height: 100% !important;
} */

.rst__virtualScrollOverride > div {
  position: unset !important;
}

.rst__rowContents {
  border-radius: 15px !important;
  box-shadow: 0px 3px 11px 2px rgb(0 0 0 / 10%) !important;
  border: none !important;
}
.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after,
.rst__lineChildren::after {
  background-color: #cfcfcf !important;
}

.rst__collapseButton,
.rst__expandButton {
  box-shadow: 0 0 0 2px #5569ff !important;
}

.minimap {
  right: 98px !important;
}

.tree-link {
  cursor: pointer;
}

.tree-link:hover {
  color: #5569ff;
  text-decoration: underline;
}

@media only screen and (max-width: 1400px) {
  .actionModal {
    height: calc(100vh - 220px);
    overflow: auto;
  }
}
